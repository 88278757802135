var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contact container" }, [
      _c(
        "div",
        { staticClass: "content inner-content" },
        [
          _c(
            "display-content",
            {
              staticClass: "display-container",
              attrs: { events: _vm.events, config: _vm.animationConfig }
            },
            [
              _c("h1", { staticClass: "header" }, [_vm._v("Contact")]),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "link-btn", attrs: { href: _vm.links.email } },
                [
                  _vm._v(
                    "\n                  kyle.evan.p@gmail.com\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "link-btn",
                  attrs: { target: "_blank", href: _vm.links.resume }
                },
                [_vm._v("\n                Resume\n              ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "link-btn",
                  attrs: { target: "_blank", href: _vm.links.github }
                },
                [
                  _vm._v(
                    "\n                github.com/KyleEvan\n              "
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }