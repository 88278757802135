var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "content inner-content" }, [
        _c("h1", [_vm._v("Projects")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "projects" },
          _vm._l(_vm.projectsData, function(work, index) {
            return _c(
              "a",
              {
                key: index,
                class: _vm.projectContainerClass,
                attrs: { role: "link", href: work.href },
                on: {
                  mousedown: function($event) {
                    $event.preventDefault()
                    return _vm.handleProjectMousedown($event)
                  },
                  mouseup: function($event) {
                    $event.preventDefault()
                    return _vm.handleProjectMouseup($event)
                  },
                  click: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "div",
                  { class: _vm.projectClass, attrs: { tabindex: "-1" } },
                  [
                    _c("div", { staticClass: "image" }, [
                      _c("img", { attrs: { src: work.featuredImage } })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _c("h3", { staticClass: "name" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(work.name) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "tags" },
                        _vm._l(work.tags, function(tag, index) {
                          return _c(
                            "li",
                            { key: index, class: tag.toLowerCase() },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(tag) +
                                  "\n                  "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      work.date
                        ? _c("div", { staticClass: "date" }, [
                            _vm._v(_vm._s(work.date))
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("svg", { staticClass: "out", attrs: { id: "transition-overlay" } }, [
        _c("rect")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }